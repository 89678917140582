<template lang="html">
    <v-card tile>
    <v-list class="py-0">
      <div v-for="(item, i) in data" :key="i">
          <v-list-group v-if="item.subpages.length > 0">
              <template v-slot:activator>
                  <v-list-item-content>
                      <v-list-item-title v-text="item.titel"></v-list-item-title>
                  </v-list-item-content>
              </template>
              <v-list-item :to="makePath(item)">
                  <v-list-item-content>
                      <v-list-item-title v-text="item.titel" class="pl-5"></v-list-item-title>
                  </v-list-item-content>
              </v-list-item>


              <div data-test  v-for="(sub, j) in item.subpages" :key="j">
                  <v-list-group sub-group v-if="sub.subpages.length > 0">
                      <template v-slot:activator>
                          <v-list-item-content>
                              <v-list-item-title v-text="sub.titel"></v-list-item-title>
                          </v-list-item-content>
                      </template>
                      <v-list-item :to="makePath(sub)">
                          <v-list-item-content>
                              <v-list-item-title v-text="sub.titel" class="pl-8"></v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>

                      <v-list-item v-for="(ssub, k) in sub.subpages" :key="k" :to="makePath(ssub)">
                          <v-list-item-content>
                              <v-list-item-title v-text="ssub.titel" class="pl-8"></v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>

                  </v-list-group>

                  <v-list-item v-else :to="makePath(sub)">
                      <v-list-item-content>
                          <v-list-item-title v-text="sub.titel" class="pl-5"></v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
              </div>

          </v-list-group>
          <v-list-item v-else :to="makePath(item)">
              <v-list-item-content>
                  <v-list-item-title v-text="item.titel"></v-list-item-title>
              </v-list-item-content>
          </v-list-item>
      </div>
  </v-list>
</v-card>
</template>

<script>
import {makePath} from '@/api/api'
export default {
    name: 'Seite',
    props: ['data'],
    methods: {
        makePath
    },
}
</script>
